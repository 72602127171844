:root {
  /*COLORS*/
  --color-primary: #5fb2cf;
  --color-primary-hover: #3b94b4;
  --color-primary-pressed: #449cbb;
  --color-primary-disabled: #2e6579;
  --color-primary-secondary-hover: #2e6579;
  --color-primary-secondary-pressed: #2e6579;

  --color-gray-9: #616161;
  --color-gray-8: #434343;
  --color-gray-7: #040404;
  --color-gray-6: #101010;
  --color-gray-55: #1C1C1C;
  --color-gray-5: #2B2B2B;
  --color-gray-45: #5E5E5E;
  --color-gray-4: #747474;
  --color-gray-35: #B8B8B8;
  --color-gray-3: #D6D6D6;
  --color-gray-2: #E9E9E9;
  --color-gray-1: #F3F3F3;
  --color-gray: #373737;

  --color-gray-contrast: #282828;
  --color-gray-dark: #242424;
  --color-background: #141414;

  --color-background-ipad: hsla(0, 0%, 5%, 0.85);
  --color-shadow-5: rgba(0, 0, 0, 0.75);
  --color-shadow-4: rgba(0, 0, 0, 0.6);
  --color-shadow-35: rgba(0, 0, 0, 0.25);
  --color-shadow-3: rgba(0, 0, 0, 0.2);
  --color-shadow-2: rgba(0, 0, 0, 0.4);
  --color-shadow-1: rgba(0, 0, 0, 0.35);

  --color-black: #000000;
  --color-white: #FFFFFF;
  --color-white-contrast: #dfdfdf;
  --color-success: #58C321;
  --color-success-dark: #0a2909;
  --color-success-background: #061f06;
  --color-warning: #FFAF37;
  --color-warning-background: #1f1403;
  --color-error: #C94E4E;
  --color-error-hover: #cc4d4d;
  --color-sold-out: #c94e4e;
  --color-error-disabled: #6d2828;
  --color-error-dark: #2b1010;
  --color-error-pressed: #0f0707;
  --color-tags: #5046B8;
  --color-info: #62A4D9;
  --color-dalay-transaction: #80B5E0;
  --color-info-background: #050c11;
  --color-credit-border-card: #3D4666;
  --color-logo-box: #03030375;
  --color-spinner: #030303;
  --color-uala: #596bc0;
  --color-uala-2: #8399ff;
  --color-violet: #3945ec;

  --color-modal: rgba(140, 140, 140, 0.35);
  --color-footer-button-fixed: rgba(43, 43, 43, 0.9);
  --color-overlay: rgba(23, 23, 23, 0.8);
  --color-dark-trans-2: rgba(16, 16, 16, 0.95);
  --color-dark-trans-1: rgba(13, 13, 13, 0.5);
  --color-dark-trans: rgba(0, 0, 0, 0.8);
  --color-hero-2: rgba(46, 46, 46, 0.8);

  --color-card-img: linear-gradient(180deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, 0.99) 100%);
  --color-linear-gradient: linear-gradient(90deg, #1C1C1C 0%, rgba(28, 28, 28, 0) 100%);
  --color-linear-gradient-3: linear-gradient(270deg, #101010 0%, rgba(16, 16, 16, 0) 100%);
  --color-linear-gradient-2: linear-gradient(3.39deg, #151515 5.59%, #313131 98.65%);
  --color-credit-card: linear-gradient(191.68deg, #464646 6.64%, #262626 89.5%);
  --color-credit-card-active: linear-gradient(1.14deg, #0069B4 0.31%, #49A5DD 98.37%);
  --color-modal-gradient: linear-gradient(180deg, #030303, #525252);

  /*FONTS*/
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'Oswald', sans-serif;
}
